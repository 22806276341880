import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, Button } from '@mui/material';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';               
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import axios from 'axios';
import CustomTextField from '../components/CustomTextField';
import IconButton from '@mui/material/IconButton';
import InstagramIcon from '@mui/icons-material/Instagram';
import { getPopUpCookie, setPopUpCookie } from '../utils/cookies';
import { Link } from 'react-router-dom';


const NewsLetterPopUp = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [formState, setFormState] = useState('initial');
    const [details, setDetails] = useState({
        email: '',
    });
    const filePath =
    process.env.NODE_ENV === 'production'
      ? 'https://silverriot.co.uk/api/'
      : 'http://127.0.0.1/api/';

      const handleChange = (e) => {
        const { name, value } = e.target;
        setDetails(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const postOrderDetails = async () => {
            setFormState('loading');
            try {
				const response = await axios.post(filePath + "get_email.php", JSON.stringify({ details:details }), {
					method: 'POST',
                    credentials: 'include',
                });
                setFormState('success');
			} catch (error) {
				console.error(error);
                setFormState('error');
			}}
        postOrderDetails();
    };

    useEffect(() => {
        if (!getPopUpCookie()) {
            const timer = setTimeout(() => {
                setIsOpen(true);
            }, 1000); // 1 second

            return () => clearTimeout(timer); // Cleanup the timer
        }
    }, []);

return (
    <Popup open={isOpen} onClose={() => {
        setIsOpen(false);
        setPopUpCookie();
        window.fbq('track', 'NewPopUp')
    }} 
        modal 
        nested 
        contentStyle={{
            backgroundColor: 'rgba(0, 0, 0, 0)',
            border: 'none',
            // width: "90vw",
            justifyContent: "center",
            display: "flex",
            // width:{xs:'40vw', md:'42vh', lg:'50vh'},
    }}>
    {close => (
        <Box sx={{ 
            animation: "fadeInUp 0.5s ease-out",
            "@keyframes fadeInUp": {
                "from": {
                  opacity: "0",
                  transform: "translateY(20px)",
                },
                "to": {
                  opacity: "1",
                  transform: "translateY(0)",
                }
            },
            backgroundColor: "white",
            width: '100%',
            height: {xs:'40vw', md:'42vh', lg:'50vh'},
            border: '2px solid #ffffff',
            backgroundColor: '#d9d9d9',
            minWidth: '250px',
            minHeight: '350px',
            maxWidth: '620px',
            margin: 'auto',
        }}>

            <Box sx={{
                width:'98%',
                background: 'linear-gradient(to right, #948F8F, #D9D9D9)',
                height:'15px',
                marginX:'1%',
                marginY:'0.5%',
                display:'flex',
                alignItems:'center',
            }}>
                <TextSnippetIcon sx={{color: '#ffffff', height:'15px', width:'15px'}}/>
                <Typography variant='h6' sx={{padding:0, margin:0,lineHeight:'10px', whiteSpace:'nowrap', fontSize:'12px', width:'40px', flexGrow:1, overflowX:'hidden', fontWeight:'600', textOverflow: 'ellipsis'}}>SILVER RIOT - Try SR Free! - Microsoft Inte...</Typography>
                <Box sx={{
                    alignSelf:'flex-end',
                    justifySelf:'flex-end',
                    width:'55px',
                    display:'flex',
                    justifyContent:'flex-end',
                    paddingLeft:'5px',
                    position:'relative',
                }}>
                    <img src='/images/retro_windows_buttons.png' height='13px' style={{
                        padding:'1px 0'
                    }} alt='retro windows tab icons' />
                    <Button sx={{color: '#ffffff', height:'15px', width:'15px', minWidth:'15px', padding:0, margin:0, zIndex:0, position:'absolute', right:0}} onClick={close}></Button>
                </Box>
            </Box>
            <Box sx={{
                width:'calc(98%-3px)',
                height:'calc(98.5% - 15px - 2px - 2px)',
                marginX:'1%',
                marginY:'0.5%',
                display:'flex',
                alignItems:'center',
                justifyContent:'center',
                borderTop:'2px solid black',
                borderBottom:'1px solid black',
                borderLeft:'2px solid black',
                borderRight:'1px solid black',
                backgroundColor:'white',
                display:"block",
                // flexDirection:"column",
            }}>
                <Box sx={{
                    display:"flex",
                    flexDirection:"column",
                    width:"100%",
                    height:"100%",
                    alignItems:"center",
                }}>
                    <Typography variant="h4" sx={{ color: 'black', fontFamily:"HelveticaNeue", fontSize:{xs:'17px', sm:'3vw', md:'3vh', lg:'4vh'}, transform: "scaleX(1.5)", width:"50%", textTransform:"uppercase", textAlign:"center", marginTop:{xs:'10%', sm:'3vh', lg:"5vh"} }}>
                        Dont miss out!
                    </Typography>
                    <Typography variant="h4" sx={{ color: 'black', fontFamily:"HelveticaNeue", fontWeight:"50", fontSize:{xs:'17px', sm:'1.8vw', md:'110%', lg:'130%'}, marginY:{xs:'2vw', sm:"1vh", md:'1.2vh', lg:'2vh'}, textAlign:"center"}}>
                        Sign up to our newsletter to not miss out on future drops 
                    </Typography>
                    {formState === 'initial' && (
                        <Box component="form" onSubmit={handleSubmit} sx={{width:{xs:"100%", md:"80%"}}}>
                            <Box>
                                <CustomTextField name="email" value={details.email} handleChange={handleChange} placeholder={"Enter your email address"} required sx={{color:"black", borderRadius:"0", width:{xs:"100%", sm:"30vw"}, maxWidth:"450px", height:{xs:'73px', sm:'5vw',}, maxHeight:"60px", fontSize:{xs:'2vw', md:'100%'}, textAlign:"center", marginY:{xs:'0', sm:'15px',}}} />
                            </Box>
                            <Box sx={{
                                display:'flex',
                                justifyContent:{xs:'center',sm:'flex-start'},
                                marginTop:{xs:'0',sm:'0'},
                            
                            }}>
                                <Button variant='outlined' type="submit" sx={{ 
                                    marginX: "10px",
                                    backgroundColor: 'black',
                                    borderRadius: '0',
                                    paddingX: {xs:'20px', md:'2vh'},
                                    '&:hover': {
                                        borderColor: 'white',
                                        backgroundColor: '#5f1c1a',
                                        borderRadius: '0',
                                    },
                                    marginY:{xs:'0', sm:'10px', md:'20px'},
                                    width: "100%",
                                    height:{xs:'73px', sm:"8vw", md:'5vw',},
                                    maxHeight:"70px",
                                }}>
                                    <Typography sx={{
                                        fontFamily: 'HelveticaNeue',
                                        fontWeight: '500',
                                        fontSize: {xs:'20px', sm:'2vw', md:'250%'},
                                        color: 'white',
                                        transform: 'scaleX(1.5)',
                                    }}>Submit</Typography>
                                </Button>
                            </Box>
                        </Box>
                    )}
                    {formState === 'loading' && (
                        <Typography color={'black'}>Loading...</Typography>
                    )}
                    {formState === 'success' && (
                        <Typography variant="h4" sx={{ color: 'black', fontFamily:"HelveticaNeue", fontWeight:"50", fontSize:{xs:'17px', sm:'1.8vw', md:'110%', lg:'130%'}, marginY:{xs:'2vw', sm:"1vh", md:'1.2vh', lg:'2vh'}, textAlign:"center"}}>
                            Thank you for signing up!
                        </Typography>
                    )}
                    {formState === 'error' && (
                        <Typography color={'black'}>Unfortunately your message couldnt be sent at this time. Please try again later.</Typography>
                    )}
                    <Box sx={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        height: "100%",
                        alignItems: "center",
                        justifyContent:{xs:'space-evenly', sm:'center',},
                        paddingBottom: {xs:'0', md:'20px',},
                    }}>
                        <IconButton component={Link} to="https://www.instagram.com/silver__riot/"
                            sx={{
                                color: 'white', // Optional: customize icon color
                                '&:hover': {
                                    backgroundColor: 'white', // Optional: hover effect
                                },
                                borderRadius: '50%', // Optional: rounded style
                                padding: '10px', // Optional: adjust padding for icon size
                                width: {xs:'5vw', md:'60px'},
                                height: {xs:'5vw', md:'60px'},
                                marginRight: {xs:'0', sm:"10px", lg:'0'},
                            }}
                        >
                            <InstagramIcon sx={{color:"black", transform:"scale(2)"}}/>
                        </IconButton>
                        <IconButton component={Link} to="https://www.tiktok.com/@silver__riot"
                            sx={{
                                color: 'white', // Optional: customize icon color
                                '&:hover': {
                                    backgroundColor: 'white', // Optional: hover effect
                                },
                                borderRadius: '50%', // Optional: rounded style
                                padding: '10px', // Optional: adjust padding for icon size
                                width: {xs:'5vw', md:'60px'},
                                height: {xs:'5vw', md:'60px'},
                                marginLeft: {xs:'0', sm:"10px", lg:'0'},
                            }}
                        >
                            <img
                                src='/images/tiktok.svg'
                                alt="TikTok Icon"
                                style={{
                                    width: "20px", // Adjust size as needed
                                    height: "20px", // Adjust size as needed
                                    transform:"scale(2)",
                                }}
                            />
                        </IconButton>

                    </Box>
                </Box>
            </Box>
        </Box>
    )}
    </Popup>
);
};

export default NewsLetterPopUp;