import React, { useEffect, useState, useRef } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { Typography, Button, Grid, Box } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import HoverableImg from '../components/HoverableImg';
import { get_product } from '../utils/common_requests';
import { addToCart } from '../utils/cookies';

function MobImgBox({ specific_image, product, setMainUrl }) {
    return (
        <Box onClick={() => setMainUrl(specific_image)}
            sx={{
                overflow: "hidden", 
                cursor: "pointer", 
                borderRight: '1px solid white',
                width: 'calc(20vw - 1px)',
                height: '20vw',
                maxWidth: '20vw',
                flexShrink: 0,
            }}>
            <img src={'/images/' + specific_image} alt={product.name} style={{width:'100%',height:'100%'}} />
        </Box>
    );
}

function ImgBox({ specific_image, product, setMainUrl, mainUrl }) {
    return (
        <Box onClick={() => setMainUrl(specific_image)}
            sx={{
                overflow: "hidden", 
                cursor: "pointer", 
                border: mainUrl === specific_image ? '3px solid white' : '1px solid white',
                width: {lg:mainUrl === specific_image ? 'calc(10vw - 6px)' :'calc(10vw - 2px)', sm:mainUrl === specific_image ? 'calc(25vw - 6px)' :'calc(25vw - 2px)'},
                height: {lg:mainUrl === specific_image ? 'calc(10vw - 6px)' :'calc(10vw - 2px)', sm:mainUrl === specific_image ? 'calc(25vw - 6px)' :'calc(25vw - 2px)'},
                maxWidth:'25vw',
            }}>
            <img src={'/images/' + specific_image} alt={product.name} style={{width:'100%',height:'100%'}} />
        </Box>
    );
}

function MobSizeButton({ product, chosenInfo, setChosenInfo, size, sizeArr }) {
    const product_info = product.size_info.filter(size_info => size_info.size == size)[0];
    return (
        <Tooltip title={product_info.number_of_stock == 0 ? "Out of stock" : ""}>
            <div>
                <Button variant="outlined" disabled={product_info.size == chosenInfo?.size || product_info.number_of_stock == 0} sx={{
                    color: 'white',
                    borderColor: sizeArr.includes(size) ? "#FFAA1D" : 'white',
                    borderRadius: '10px',
                    padding: '10px',
                    paddingX: '20px',
                    textTransform: 'none',
                    fontFamily: 'Poppins',
                    fontWeight: '400',
                    fontSize: '5vw',
                    '&:hover': {
                        borderColor: 'white',
                        
                    },
                    '&:disabled': {
                            backgroundColor: product_info.number_of_stock == 0 ? '#9F9F9F' : '#E51E10',
                            color: product_info.number_of_stock == 0 ? '#0B0A0A' : 'white',
                        },
                    marginTop:'20px',
                    width:'100%',
                    height:'13vw',

                }} onClick={() => setChosenInfo(product_info)}>{product_info.size}</Button>
            </div>
        </ Tooltip>
    )
}

function SizeButton({ product, chosenInfo, setChosenInfo, size, sizeArr }) {
    const product_info = product.size_info.filter(size_info => size_info.size == size)[0];

    return (
        <Tooltip title={product_info.number_of_stock == 0 ? "Out of stock" : ""}>
            <div>
                <Button variant="outlined" disabled={product_info.size == chosenInfo?.size || product_info.number_of_stock == 0} sx={{
                    color: 'white',
                    borderColor: sizeArr.includes(size) ? "#FFAA1D" : 'white',
                    borderRadius: '10px',
                    padding: '10px',
                    paddingX: '20px',
                    textTransform: 'none',
                    fontFamily: 'Poppins',
                    fontWeight: '400',
                    fontSize: {md:'3vw', sm:'2vw'},
                    '&:hover': {
                        borderColor: 'white',
                        
                    },
                    '&:disabled': {
                            backgroundColor: product_info.number_of_stock == 0 ? '#9F9F9F' : '#E51E10',
                            color: product_info.number_of_stock == 0 ? '#0B0A0A' : 'white',
                        },
                    marginTop:'10px',
                    marginRight:'10px',
                    width:'100%',
                    height:{md:'8vw', lg:'4vw'},

                }} onClick={() => setChosenInfo(product_info)}>{product_info.size}</Button>
            </div>
        </ Tooltip>
    )
}


function Product({ cart, handleAddToCart, handleRemoveFromCart, isMobile }) {
    const { id } = useParams();
    const [product, setProduct] = useState({name: 'Loading...'});
    const [sizeArr, setSizeArr] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const [mainUrl, setMainUrl] = useState(null);
    const [chosenInfo , setChosenInfo] = useState(null);
    
    const navigate = useNavigate();
    const scrollContainerRef = useRef(null);
    
    useEffect(() => {
        const fetchProduct = async () => {
            const prod = await get_product(id); // Await the async function    
            setProduct(prod); // Set product data
            setMainUrl(prod.image_url_0);
            if (prod !== undefined) {
                setIsLoading(false); // Set loading state to false
                window.fbq('track', 'ViewContent', { content_name: prod.name, });
            }
        };
        fetchProduct(); // Invoke the async function
    }, [id]);

    const handlePrevClick = () => {
        if (scrollContainerRef.current) {
            const scrollAmount = window.innerWidth * 0.2; // 20% of the viewport width
            scrollContainerRef.current.scrollBy({ left: -scrollAmount, behavior: 'smooth' });
        }
    };
    
    const handleNextClick = () => {
        if (scrollContainerRef.current) {
            const scrollAmount = window.innerWidth * 0.2; // 20% of the viewport width
            scrollContainerRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
        }
    };

    useEffect(() => { // creates new cart with just this id
        let newCart = cart.filter(obj => obj.id === id);
        setSizeArr(newCart.map(obj => obj.size));
    }, [id, cart]);  

    const handleBuyNow = () => {
        if (chosenInfo == null) {
        } else {

            handleAddToCart({"id":id, "size":chosenInfo.size});
            window.fbq('track', 'AddToCart', { content_name: product.name, content_ids: [id], content_type: 'product', value: product.price, currency: 'GBP' });
            navigate('/checkout',{ state: { cartList: [sanitizeProduct(product, chosenInfo)], totalPrice: product.price  }, replace: true});
           
        }
    }
    const sanitizeProduct = (product, chosenInfo) => {
        console.log(product, "before location")
        let sanitizedProduct = {
            name: product.name,
            size: chosenInfo.size,
            quantity: 1,
            price: product.price,
            id: product.id,
            price_stripe_id : product.price_stripe_id,
        };
        return sanitizedProduct
    };
    

    return (
        <>
            {isLoading ? (
                <></>
            ) : (
                <>
                {isMobile ? (
                <div>
                    <Box sx={{
                        display:'flex',
                        flexDirection:'column',
                        width:'100vw',
                    }}>
                        <Box sx={{
                            width:'100vw',
                            // maxWidth: '600px',
                            height:'100vw',
                            // maxHeight: '600px',

                        }}>
                            <img src={'/images/' + mainUrl} alt={product.name} style={{width:'100%', height:'100%'}} />
                        </Box>
                        <Box sx={{
                            display:'flex',
                            flexDirection:'row',
                            borderTop: '1px solid white',
                        }}>
                            <Button onClick={handlePrevClick} sx={{
                                width: '10vw',
                                height: '20vw',
                                color: 'white',
                                minWidth: '10px',
                                borderRight: '1px solid white',
                                borderRadius: '0',

                            }}>
                                <Typography sx={{ transform: 'scaleY(1.5)', fontSize:"5vw", fontFamily: "Raleway", marginBottom:'8%' }}>&lt;</Typography>
                            </Button>
                            <Box 
                                ref={scrollContainerRef}
                                sx={{
                                    display:'flex',
                                    flexDirection:'row',
                                    width: '80vw',
                                    overflow: "scroll", 
                                    scrollbarWidth: 'none',
                                    msOverflowStyle: 'none',
                                    '&::-webkit-scrollbar': {
                                        display: 'none',
                                    },
                                    borderBottom: '1px solid white',

                            }}>
                                <MobImgBox product={product} specific_image={product.image_url_0} setMainUrl={setMainUrl}/>
                                <MobImgBox product={product} specific_image={product.image_url_1} setMainUrl={setMainUrl}/>
                                <MobImgBox product={product} specific_image={product.image_url_2} setMainUrl={setMainUrl}/>
                                <MobImgBox product={product} specific_image={product.image_url_3} setMainUrl={setMainUrl}/>
                                <MobImgBox product={product} specific_image={product.image_url_4} setMainUrl={setMainUrl}/>
                                <MobImgBox product={product} specific_image={product.image_url_5} setMainUrl={setMainUrl}/>
                                <MobImgBox product={product} specific_image={product.image_url_6} setMainUrl={setMainUrl}/>
                            </Box>
                            <Button onClick={handleNextClick} sx={{
                                width: '10vw',
                                height: '20vw',
                                color: 'white',
                                minWidth: '10px',
                                borderLeft: '1px solid white',
                                borderRadius: '0',

                            }}>
                                <Typography sx={{ transform: 'scaleY(1.5)', fontSize:"5vw", fontFamily: "Raleway", marginBottom:'8%' }}>&gt;</Typography>
                            </Button>
                        </Box>
                        <Box sx={{
                            display: 'flex', 
                            flexDirection: 'column',
                        }}>
                            <Typography variant="h4" color='white' fontFamily="HelveticaNeue" textTransform="uppercase" sx={{paddingX:'5vw', paddingTop:'5vw', width:"80vw", fontSize:"4rem"}}>{product.name}</Typography>
                            {product.secondary_name ? (
                                <Typography variant="h6" color='white' fontFamily="HelveticaNeue" textTransform="uppercase" sx={{paddingX:'5vw', fontSize:"2rem"}}>[{product.secondary_name}]</Typography>
                            ):(null)}
                            <Typography variant="body1" color="white" fontFamily="HelveticaNeue" fontWeight="200" sx={{paddingX:'5vw', fontSize:"2rem"}}>{product.out_of_stock == 1 ? `£${product.price} - This product is currently out of stock` : `£${product.price}`}</Typography>
                            <Typography variant="body1" marginTop="5%" paddingX='5vw' color="white">{product.description_0}</Typography>
                            <Typography variant="h4" color='white' fontFamily="HelveticaNeue" marginTop="5%" paddingX='5vw' textTransform="uppercase">Size:</Typography>
                            <Grid container spacing={4} sx={{
                                paddingX:'5vw',
                                display:'flex',
                                flexDirection:'row',
                                justifyContent:'space-between',

                            }}>
                                {product.size_info.map((size_info) => (
                                    <Grid item xs={4} key={size_info.size}>
                                        <MobSizeButton product={product} chosenInfo={chosenInfo} setChosenInfo={setChosenInfo} size={size_info.size} sizeArr={sizeArr} />
                                    </Grid>
                                ))}
                            </Grid>
                            <Tooltip title={chosenInfo == null ? "Please pick a size" : ""}>
                                <Button variant="outlined"  
                                    disabled={product.out_of_stock} 
                                    sx={{
                                        color: 'white',
                                        borderColor: 'white',
                                        borderRadius: '10px',
                                        padding: '10px',
                                        paddingX: '20px',
                                        textTransform: 'none',
                                        fontFamily: 'Poppins',
                                        fontWeight: '400',
                                        fontSize: '5vw',
                                        '&:hover': {
                                            borderColor: 'white',
                                            cursor: chosenInfo == null ? 'not-allowed' : 'pointer', 
                                        },
                                        '&:disabled': {
                                            backgroundColor: '#9F9F9F',
                                            color: '#0B0A0A',
                                        },
                                        marginTop:'4vw',
                                        marginX:'5vw',
                                        height:'13vw',
                                        
                                    }} 
                                    onClick={() => {
                                        if (chosenInfo !== null) {
                                            handleAddToCart({"id":id, "size":chosenInfo.size})
                                            window.fbq('track', 'AddToCart', { content_name: product.name, content_ids: [id], content_type: 'product', value: product.price, currency: 'GBP' });
                                        }
                                }}>
                                    {product.out_of_stock ? "Out of Stock" : "Add To Cart"}
                                </Button>
                            </Tooltip>
                            {cart.length === 0 && !product.out_of_stock ? (
                                <Tooltip title={chosenInfo == null ? "Please pick a size" : ""}>
                                    <Button variant="outlined" sx={{
                                        color: '#0B0A0A',
                                        backgroundColor: 'white',
                                        borderColor: '#0B0A0A',
                                        borderRadius: '10px',
                                        padding: '10px',
                                        paddingX: '20px',
                                        textTransform: 'none',
                                        fontFamily: 'Poppins',
                                        fontWeight: '400',
                                        fontSize: '5vw',
                                        '&:hover': {
                                            borderColor: '#0B0A0A',
                                            backgroundColor: '#9F9F9F',
                                            cursor: chosenInfo == null ? 'not-allowed' : 'pointer', 
                                        },
                                        marginTop:'4vw',
                                        marginX:'5vw',
                                        height:'13vw',
                                        }} 
                                        onClick={() => handleBuyNow()}>
                                        Buy Now
                                    </Button>
                                </Tooltip>
                            ) : sizeArr.includes(chosenInfo?.size) ? (
                                <Button variant="outlined" sx={{
                                    color: 'white',
                                    backgroundColor: '#721E1D',
                                    borderColor: 'white',
                                    borderRadius: '10px',
                                    padding: '10px',
                                    paddingX: '20px',
                                    textTransform: 'none',
                                    fontFamily: 'Poppins',
                                    fontWeight: '400',
                                    fontSize: '5vw',
                                    '&:hover': {
                                        borderColor: 'white',
                                        backgroundColor: '#5f1c1a',
                                    },
                                    marginTop:'4vw',
                                    marginX:'5vw',
                                    height:'13vw',
                                    }} 
                                    onClick={() => {
                                        handleRemoveFromCart({"id":id, "size":chosenInfo.size})
                                }}>
                                    Remove from cart
                                </Button>
                            ) : (
                                null
                            )}
                            <Grid item xs={12}>
                                <Box sx={{
                                    width:'100%',
                                    display:'flex',
                                    alignItems:'center',
                                    justifyContent:'center',
                                    flexDirection:'column',
                                }}>
                                    <Typography variant="h6" color="white" marginTop="5%" sx={{
                                        textAlign: 'center',
                                        paddingY: '2px',
                                        paddingX: '20px',
                                        borderBottom: '2px solid white',
                                        width:'80vw',
                                        fontFamily:'Poppins',
                                        fontWeight:'400',
                                        marginX:'5vw',
                                        fontSize:'5.5vw',
                                    }}>Additional Information</Typography>
                                    <Box sx={{
                                        width:'80%',
                                    }}>

                                        <Typography variant="body1" color="white" sx={{ fontFamily:'Poppins', fontWeight:'500', marginY:'20px', fontSize:"30px", }}>Properties</Typography>
                                        {chosenInfo == null ? (
                                            <Typography variant="body1" color="#9F9F9F" sx={{ fontFamily:'Poppins', fontWeight:'400', justifyContent:'space-between', display:"flex"}}>To see properties, please choose a size.</Typography>
                                        ) : (
                                            <>
                                                <Typography variant="body1" color="#9F9F9F" sx={{ fontFamily:'Poppins', fontWeight:'400', justifyContent:'space-between', display:"flex"}}><span>Weight</span> <span>{chosenInfo.weight}g</span></Typography>
                                                <hr color='#9F9F9F'/>
                                                <Typography variant="body1" color="#9F9F9F" sx={{ fontFamily:'Poppins', fontWeight:'400', justifyContent:'space-between', display:"flex", marginTop:'20px'}}>
                                                    <span>Sizing</span> 
                                                    <span>{chosenInfo.size} - {chosenInfo.length} {product.type === 'bracelet' ? 'cm' : <Link to='/info/ring-sizing' style={{color:'white'}}>See ring sizing</Link>}</span>
                                                </Typography>
                                                <hr color='#9F9F9F'/>
                                                <Typography variant="body1" color="#9F9F9F" sx={{ fontFamily:'Poppins', fontWeight:'400', justifyContent:'space-between', display:"flex", marginTop:'20px'}}><span>{product.clasp !== '' ? 'Clasp type' : ''}</span> <span>{product.clasp !== '' ? `${product.clasp}` : ''}</span></Typography>
                                                {product.clasp !== '' ? <hr color='#9F9F9F'/> : ''}
                                            </>
                                        )}


                                        <Typography variant="body1" color="white" sx={{ fontFamily:'Poppins', fontWeight:'500', marginTop:'40px', marginBottom:'10px', fontSize:"30px", }}>Manufacturing</Typography>
                                        <Typography variant="body1" color="#9F9F9F" sx={{ fontFamily:'Poppins', fontWeight:'400', marginY:'30px'}}>{product.manufacturing}</Typography>
                                        <hr color='#9F9F9F'/>

                                        <Typography variant="body1" color="white" sx={{ fontFamily:'Poppins', fontWeight:'500', marginTop:'40px', marginBottom:'10px', fontSize:"30px", }}>Care</Typography>
                                        <Typography variant="body1" color="#9F9F9F" sx={{ fontFamily:'Poppins', fontWeight:'400', marginY:'30px'}}>{product.care}</Typography>
                                        <hr color='#9F9F9F'/>

                                        <Typography variant="body1" color="white" sx={{ fontFamily:'Poppins', fontWeight:'500', marginTop:'40px', marginBottom:'10px', fontSize:"30px", }}>Material</Typography>
                                        <Typography variant="body1" color="#9F9F9F" sx={{ fontFamily:'Poppins', fontWeight:'400', marginY:'30px'}}>{product.material}</Typography>
                                    </Box>
                                   
                                </Box>
                            </Grid>
                        </Box>
                    </Box>
                </div>

            ) : (

                <div>
                    <Grid container sx={{
                        backgroundColor:'#0B0A0A',
                    }}>
                        <Grid item textAlign="center" display="flex" alignItems='center'>
                            <Box component={Link} to="/shop" sx={{
                                padding: '0',
                                margin: '10px',
                                textTransform: 'none',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '3vh',
                                width: '80%',
                                marginRight:'0',
                                marginLeft:'20px',
                                color:"#9F9F9F",
                                fontFamily:"Poppins",
                                fontWeight:"500",   
                                fontSize: { 
                                        xs: '2vw', 
                                        md: '1.5vw', 
                                        lg: '1vw', 
                                    },
                                textDecoration: 'none',
                                "&:hover": {
                                        textDecoration: 'underline',
                                    },                  
                            }}>
                                Home
                            </Box>
                        </Grid>
                        <Grid item textAlign="center" display="flex" alignItems='center'>
                            <Typography variant="h4" color="white" fontFamily="Poppins" fontWeight="500" fontSize='3vw' height="100%" sx={{
                                width: '100%', // Fill available width
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                
                            }}>
                                <ArrowForwardIosIcon sx={{marginY: 'auto', paddingBottom:'0px'}}/>
                            </Typography>
                        </Grid>
                        <Grid item textAlign="center" display="flex" alignItems='center'>
                            <Box component={Link} to="/shop" sx={{
                                padding: '0',
                                margin: '10px',
                                textTransform: 'none',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '3vh',
                                width: '80%',
                                marginRight:'0',
                                marginLeft:'5px',
                                color:"#9F9F9F",
                                fontFamily:"Poppins",
                                fontWeight:"500",   
                                fontSize: { 
                                        xs: '2vw', 
                                        md: '1.5vw', 
                                        lg: '1vw', 
                                    },
                                textDecoration: 'none',
                                "&:hover": {
                                        textDecoration: 'underline',
                                    },                  
                            }}>
                                Shop
                            </Box>
                        </Grid>
                        <Grid item textAlign="center" display="flex" alignItems='center'>
                            <Typography variant="h4" color="white" fontFamily="Poppins" fontWeight="500" fontSize='3vw' height="100%" sx={{
                                width: '100%', // Fill available width
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                
                            }}>
                                <ArrowForwardIosIcon sx={{marginY: 'auto', paddingBottom:'0px'}}/>
                            </Typography>
                        </Grid>
                        <Grid item textAlign="center" display="flex" >
                            <Box sx={{
                            borderLeft: '1px solid white',
                            marginX: '20px',
                            marginY: '10px',
                            paddingX: '20px',
                        }}>

                            <Typography variant="h6" color="white" fontFamily="Poppins" fontWeight="300" fontSize='3vw'
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                textTransform: 'uppercase',
                                height: '100%',
                                fontSize: { 
                                    xs: '2vw', 
                                    md: '1.5vw', 
                                    lg: '1vw', 
                                },
                            }}>
                                {product.secondary_name ? product.name + '[' + product.secondary_name + ']': product.name}
                            </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    {product ? (    
                    <Box sx={{
                        display:'flex',
                        flexDirection:'column',
                    }}>
                        <Box sx={{
                            display:'flex',
                            flexDirection:{lg:'row',xs:'column'}
                        }}>
                            <Box sx={{
                                display:'flex',
                                flexDirection:{sm:'row',xs:'column'},
                                width:{lg:'50vw', xs:'100vw'},
                                height:{lg:'40vw', sm:'50vw', xs:'125vw'},
                            }}>
                                <Box sx={{
                                    display:'flex',
                                    flexDirection:{sm:'column', xs:'row'},
                                    order:{lg:1, sm:1, xs:2},
                                }}>
                                    <Box sx={{
                                        width:{lg:'10vw', sm:'25vw', xs:'50vw'},
                                        height:{lg:'20vw', sm:'50vw', xs:'25vw'},
                                        display:'flex',
                                        flexDirection:{sm:'column', xs:'row'},
                                    }}>
                                        <ImgBox specific_image={product.image_url_0} product={product} setMainUrl={setMainUrl} mainUrl={mainUrl}/>
                                        <ImgBox specific_image={product.image_url_1} product={product} setMainUrl={setMainUrl} mainUrl={mainUrl}/>
                                    </Box>
                                    <Box sx={{
                                        width:{lg:'10vw', sm:'0vw', xs:'50vw'},
                                        height:{lg:'20vw', sm:'0vw', xs:'25vw'},
                                        display:{lg:'block', sm:'none', xs:'flex'},
                                        flexDirection:{sm:'column', xs:'row'},
                                    }}>
                                        <ImgBox specific_image={product.image_url_2} product={product} setMainUrl={setMainUrl} mainUrl={mainUrl}/>
                                        <ImgBox specific_image={product.image_url_3} product={product} setMainUrl={setMainUrl} mainUrl={mainUrl}/>
                                    </Box>
                                </Box>
                                <Box sx={{
                                    width:{xl:'40vw', lg:'40vw', sm:'50vw'},
                                    height:{xl:'40vw', lg:'40vw', sm:'50vw'},
                                    order:{lg:2, sm:2, xs:1},
                                }}>
                                    <HoverableImg src={'/images/' + mainUrl} alt={product.name} width={{xl:'40vw', lg:'50vw', sm:'100vw'}} height={{xl:'40vw', lg:'50vw', sm:'100vw'}}/>
                                </Box>
                                <Box sx={{
                                        width:{lg:'0vw', sm:'25vw'},
                                        height:{lg:'0vw', sm:'50vw'},
                                        display:{lg:'none', sm:'block', xs:'none'},
                                        order:{lg:3, sm:3, xs:3},
                                }}>
                                    <ImgBox specific_image={product.image_url_2} product={product} setMainUrl={setMainUrl} mainUrl={mainUrl}/>
                                    <ImgBox specific_image={product.image_url_3} product={product} setMainUrl={setMainUrl} mainUrl={mainUrl}/>
                                </Box>
                            </Box>  

                            <Box sx={{
                                width:{lg:'50vw'},
                                height: { lg: 'calc(40vw - 2px)', xs:'auto' },
                                display: 'flex', 
                                flexDirection: 'column',
                                borderTop: {lg:'1px solid white', xs:'none'},
                                borderBottom: '1px solid white',
                            }}>
                                <Typography variant="h4" color='white' fontFamily="HelveticaNeue" textTransform="uppercase" sx={{paddingX:'1vw', paddingTop:'0.5vw'}}>{product.name}</Typography>
                                {product.secondary_name ? (
                                    <Typography variant="h6" color='white' fontFamily="HelveticaNeue" textTransform="uppercase" sx={{paddingX:'1vw'}}>[{product.secondary_name}]</Typography>
                                ):(null)}
                                <Typography variant="body1" color="#9F9F9F" sx={{ paddingX: '1vw' }}>{product.out_of_stock == 1 ? `£${product.price} - This product is currently out of stock` : `£${product.price}`}</Typography>
                                <Typography variant="body1" marginTop={{sm:"5%", lg:"1%", xl:"5%"}} paddingX='1vw' color="white">{product.description_0}</Typography>
                                <Typography variant="body1" marginTop={{sm:"5%", lg:"1%", xl:"5%"}} paddingX='1vw' color="#9F9F9F">Size</Typography>
                                <Grid container spacing={{md:3, lg:1}} sx={{
                                    display:'flex',
                                    flexDirection:'row',
                                    paddingLeft:'1vw',
                                }}>
                                    {product.size_info.map((size_info, index) => (
                                        <Grid item xs={2} key={index}>
                                            <SizeButton product={product} chosenInfo={chosenInfo} setChosenInfo={setChosenInfo} size={size_info.size} sizeArr={sizeArr} />
                                        </ Grid>
                                    ))}
                                </Grid>
                                <div style={{ flexGrow: 1 }}></div>
                                <Box sx={{ paddingX:'1vw', paddingBottom:'1vw', display:'flex', flexDirection:'row', justifyContent:cart.includes(id) ? "space-between" : 'start'}}>
                                    <Tooltip title={chosenInfo == null ? "Please pick a size" : ""}>
                                        <Button variant="outlined"  
                                            disabled={product.out_of_stock} 
                                            sx={{
                                                color: 'white',
                                                borderColor: 'white',
                                                borderRadius: '10px',
                                                padding: '10px',
                                                paddingX: '20px',
                                                textTransform: 'none',
                                                fontFamily: 'Poppins',
                                                fontWeight: '400',
                                                fontSize: '2vw',
                                                '&:hover': {
                                                    borderColor: 'white',
                                                    cursor: chosenInfo == null ? 'not-allowed' : 'pointer',                                                 
                                                },
                                                '&:disabled': {
                                                    backgroundColor: '#9F9F9F',
                                                    color: '#0B0A0A',
                                                },
                                                marginTop:{sm:"4vw", lg:"2%", xl:"2vw"},
                                                height:'6vw',
                                            }} 
                                            onClick={() => {
                                                if (chosenInfo !== null) {
                                                    handleAddToCart({"id":id, "size":chosenInfo.size})
                                                    window.fbq('track', 'AddToCart', { content_name: product.name, content_ids: [id], content_type: 'product', value: product.price, currency: 'GBP' });

                                                }
                                        }}>
                                            {product.out_of_stock ? "Out of Stock" : "Add To Cart"}
                                        </Button>
                                    </Tooltip>
                                    {cart.length === 0 && !product.out_of_stock ? (
                                        <Tooltip title={chosenInfo == null ? "Please pick a size" : ""}>
                                            <Button variant="outlined" sx={{
                                                color: '#0B0A0A',
                                                backgroundColor: 'white',
                                                borderColor: '#0B0A0A',
                                                borderRadius: '10px',
                                                padding: '10px',
                                                paddingX: '20px',
                                                textTransform: 'none',
                                                fontFamily: 'Poppins',
                                                fontWeight: '400',
                                                fontSize: '2vw',
                                                '&:hover': {
                                                    borderColor: '#0B0A0A',
                                                    backgroundColor: '#9F9F9F',
                                                    cursor: chosenInfo == null ? 'not-allowed' : 'pointer', 
                                                },
                                                marginTop:{sm:"4vw", lg:"2%", xl:"2vw"},
                                                height:'6vw',
                                                }} 
                                                onClick={() => handleBuyNow()}>
                                                Buy Now
                                            </Button>
                                        </Tooltip>
                                    ) : sizeArr.includes(chosenInfo?.size) ? (
                                        <Button variant="outlined" sx={{
                                            color: 'white',
                                            backgroundColor: '#721E1D',
                                            borderColor: 'white',
                                            borderRadius: '10px',
                                            padding: '10px',
                                            paddingX: '20px',
                                            textTransform: 'none',
                                            fontFamily: 'Poppins',
                                            fontWeight: '400',
                                            fontSize: '2vw',
                                            '&:hover': {
                                                borderColor: 'white',
                                                backgroundColor: '#5f1c1a',
                                            },
                                            marginTop:{sm:"4vw", lg:"2%", xl:"2vw"},
                                            height:'6vw',
                                            }} 
                                            onClick={() => {
                                                handleRemoveFromCart({"id":id, "size":chosenInfo.size})
                                        }}>
                                            Remove from cart
                                        </Button>
                                    ) : (
                                        null
                                    )}
                                </Box>
                            </Box>
                        </Box>    
                        
                        <Grid item xs={12}>
                            <Box sx={{
                                width:'100%',
                                display:'flex',
                                alignItems:'center',
                                justifyContent:'center',
                                flexDirection:'column',
                            }}>
                                <Typography variant="h6" color="white" marginTop="5%" sx={{
                                    textAlign: 'center',
                                    paddingY: '2px',
                                    paddingX: '20px',
                                    borderBottom: '2px solid white',
                                    width:'auto',
                                    fontFamily:'Poppins',
                                    fontWeight:'400',

                                }}>Additional Information</Typography>
                                <Box sx={{
                                    width:'80%',
                                }}>
                                    <Typography variant="body1" color="white" sx={{ fontFamily:'Poppins', fontWeight:'400', marginTop:'20px'}}><span style={{fontWeight:'700', color:'white', fontSize:'30px' }}>Material:</span> {product.material}</Typography>
                                    <Typography variant="body1" color="white" sx={{ fontFamily:'Poppins', fontWeight:'400', marginTop:'20px'}}><span style={{fontWeight:'700', color:'white', fontSize:'30px' }}>Care:</span> {product.care}</Typography>
                                    <Typography variant="body1" color="white" sx={{ fontFamily:'Poppins', fontWeight:'400', marginTop:'20px'}}><span style={{fontWeight:'700', color:'white', fontSize:'30px' }}>Manufacturing:</span> {product.manufacturing}</Typography>
                                    <Typography variant="body1" color="white" sx={{ fontFamily:'Poppins', fontWeight:'400', marginTop:'20px'}}><span style={{fontWeight:'700', color:'white', fontSize:'30px' }}>Properties:</span></Typography>

                                    {chosenInfo == null ? (
                                        <Typography variant="body1" color="white" sx={{ fontFamily:'Poppins', fontWeight:'400', justifyContent:'space-between', display:"flex"}}>To see properties, please choose a size.</Typography>
                                    ) : (
                                        <>
                                            <Typography variant="body1" color="white" sx={{ fontFamily:'Poppins', fontWeight:'400'}}><span>Weight:</span> <span>{chosenInfo.weight}g</span></Typography>
                                            <Typography variant="body1" color="white" sx={{ fontFamily:'Poppins', fontWeight:'400'}}><span>Sizing: </span> <span>{chosenInfo.size} - {chosenInfo.length} {product.type === 'bracelet' ? 'cm' : <Link to='/info/ring-sizing' style={{color:'white'}}>See ring sizing</Link>}</span></Typography>
                                            <Typography variant="body1" color="white" sx={{ fontFamily:'Poppins', fontWeight:'400'}}>{product.clasp !== '' ? `Clasp Type: ${product.clasp}` : ''}</Typography>
                                        </>
                                    )}
                                </Box>
                                <Box sx={{
                                    width:'100%',
                                    display:'flex',
                                    flexDirection:'row',
                                    height:'25vw',
                                    justifyContent:'space-evenly',
                                    marginY:'30px'
                                }}>
                                    <HoverableImg src={'/images/' + product.image_url_4} alt={product.name} ratio={1} width={'25%'}/>
                                    <HoverableImg src={'/images/' + product.image_url_5} alt={product.name} ratio={1} width={'25%'}/>
                                    <HoverableImg src={'/images/' + product.image_url_6} alt={product.name} ratio={1} width={'25%'}/>
                                </Box>
                            </Box>
                        </Grid>
                    </Box>
                ) : (
                    <Typography variant="body1">Loading...</Typography>
                )}
                </div>
            )}
            </>
            )}
            
        </>
    );
}

export default Product;
