import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import HeroImgHeader from '../components/HeroImgHeader';
import { Typography, Grid, Button, Checkbox } from '@mui/material';
import CustomTextField from '../components/CustomTextField';
import CustomCountryField from '../components/CustomCountryField';
import axios from 'axios';
import {loadStripe} from '@stripe/stripe-js';
import { EmbeddedCheckoutProvider, EmbeddedCheckout
} from '@stripe/react-stripe-js';
import '@geoapify/geocoder-autocomplete/styles/minimal.css';
import { v4 as uuidv4 } from 'uuid'; // Importing uuidv4 function from uuid library
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDolly, faCreditCard } from '@fortawesome/free-solid-svg-icons';
import Radio from '@mui/material/Radio';
// import TestColours from '../components/TestColours';

function Checkout({ isMobile }) {
    const stripePromise = 
        process.env.NODE_ENV === 'production'
            ? loadStripe(process.env.REACT_APP_STRIPE_KEY_LIVE)
            : loadStripe(process.env.REACT_APP_STRIPE_KEY_TEST);
    const location = useLocation();
    const cartList = location.state.cartList;
    const totalPrice = location.state.totalPrice;
    const [showStripe, setShowStripe] = useState(false);
    const [uuid, setUuid] = useState('');
    const [tandcChecked, setTandcChecked] = useState(false);
    const [details, setDetails] = useState({
        fullName: '',
        companyName: '',
        country: '',
        // city: '',
        streetAddress: '',
        postcode: '',
        phone: '',
        email: ''
    });
    console.log(cartList, "after location")
    const [shippingOrPayment, setShippingOrPayment] = useState('shipping');
    const [estimatedArriavalDate, setEstimatedArriavalDate] = useState('');
    const filePath =
  		process.env.NODE_ENV === 'production'
			? 'https://silverriot.co.uk/api/'
			: 'http://127.0.0.1/api/';
    const [clientSecret, setClientSecret] = useState('');

    useEffect(() => {
		const startSession = async () => {
			try {
				const response = await axios.post(filePath + "create_stripe_session.php",{ cartList:cartList, uuid:uuid,} , {
					method: 'POST',
                    credentials: 'include',
                });
				setClientSecret(response.data.clientSecret);
                let id_list = [];
                for (let i = 0; i < cartList.length; i++) {
                    id_list.push(cartList[i].id);
                }
                window.fbq('track', 'InitiateCheckout', { value: totalPrice, content_ids: id_list, content_type: 'product', });
			} catch (error) {
				console.error(error);
			}
		};
        if (uuid === '') {
            return;
        }
        startSession();
    }, [cartList, filePath, uuid]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setDetails(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        
        e.preventDefault();
        const postOrderDetails = async () => {
            try {
				const response = await axios.post(filePath + "create_order.php", JSON.stringify({ details:details, cartList:cartList, uuid:uuid }), {
					method: 'POST',
                    credentials: 'include',
                });
                window.fbq('track', "AddPaymentInfo")
			} catch (error) {
				console.error(error);
			}}
        postOrderDetails();
        setShowStripe(true);
        setShippingOrPayment(!shippingOrPayment);
    };

    useEffect(() => {
        // Generate UUID when the component mounts
        const generatedUuid = uuidv4();
        setUuid(generatedUuid);
    }, []);

    useEffect(() => {
        const date = new Date();
        date.setDate(date.getDate() + 3);

        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'short' }); // 'Nov' format
        const year = date.getFullYear();


        setEstimatedArriavalDate(`${day} ${month}, ${year}`);    
    })
    return (
        <> 
        {isMobile ? (
            <div>
                <Grid container sx={{width:"90vw", margin:'auto'}}>
                    <Grid item container xs={6} marginY="30px" paddingX="10px" component={Button} onClick={() => setShippingOrPayment(!shippingOrPayment)} disabled={shippingOrPayment} sx={{
                        '&:hover': {
                            backgroundColor: '#0B0A0A',
                        },
                    }}>
                        <Grid item xs={3} justifyContent="center" alignItems="center" display="flex">
                            <FontAwesomeIcon icon={faDolly} color={shippingOrPayment ? "white" : "#9F9F9F"} fontSize="8vw"/>
                        </Grid>
                        <Grid item xs={9} sx={{display:"flex", flexDirection:"column"}}>
                            <Typography variant="body1" color={shippingOrPayment ? "white" : "#575757"} sx={{ fontFamily:'Poppins', fontWeight:'500', fontSize:"3vw", textTransform:"capitalize" }}>Step 2</Typography>
                            <Typography variant="body1" color={shippingOrPayment ? "white" : "#575757"} sx={{ fontFamily:'Poppins', fontWeight:'500', fontSize:"5vw", textTransform:"capitalize" }}>Shipping</Typography>
                        </Grid>
                    </Grid>

                    <Grid item container xs={6} marginY="30px" paddingX="10px">
                        <Grid item xs={3} justifyContent="center" alignItems="center" display="flex">
                            <FontAwesomeIcon icon={faCreditCard} color={!shippingOrPayment ? "white" : "#575757"} fontSize="8vw"/>
                        </Grid>
                        <Grid item xs={9} sx={{display:"flex", flexDirection:"column"}}>
                            <Typography variant="body1" color={!shippingOrPayment ? "white" : "#575757"} sx={{ fontFamily:'Poppins', fontWeight:'500', fontSize:"3vw", textTransform:"capitalize" }}>Step 3</Typography>
                            <Typography variant="body1" color={!shippingOrPayment ? "white" : "#575757"} sx={{ fontFamily:'Poppins', fontWeight:'500', fontSize:"5vw", textTransform:"capitalize" }}>Payment</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                {shippingOrPayment ? (
                    <form onSubmit={handleSubmit}>
                        <Grid item xs={12} sm={6} sx={{padding:'2%', paddingX:{xs:"5vw", md:'1%', lg:'10%', xl:'20%'}}}>
                            <Typography variant="body1" color="white" sx={{ fontFamily:'Poppins', fontWeight:'500', fontSize:"5vw", marginX:'10px' }}>
                                Shipping details
                            </Typography>
                            <Grid container  >
                                <Grid item xs={12}>
                                    <Typography sx={{ fontFamily:'Poppins', fontWeight:'500', color:'white', marginLeft:'10px'}}>Full Name</Typography>
                                    <CustomTextField name="fullName" value={details.fullName} handleChange={handleChange} required />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography sx={{ fontFamily:'Poppins', fontWeight:'500', color:'white', marginLeft:'10px'}}>Postcode</Typography>
                                    <CustomTextField name="postcode" value={details.postcode} handleChange={handleChange} required />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography sx={{ fontFamily:'Poppins', fontWeight:'500', color:'white', marginLeft:'10px'}}>Country / Region</Typography>
                                    <CustomCountryField name="country" value={details.country} handleChange={handleChange} required />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography sx={{ fontFamily:'Poppins', fontWeight:'500', color:'white', marginLeft:'10px'}}>Street Address</Typography>
                                    <CustomTextField name="streetAddress" value={details.streetAddress} handleChange={handleChange} required />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography sx={{ fontFamily:'Poppins', fontWeight:'500', color:'white', marginLeft:'10px'}}>Phone number</Typography>
                                    <CustomTextField name="phone" value={details.phone} handleChange={handleChange} required />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography sx={{ fontFamily:'Poppins', fontWeight:'500', color:'white', marginLeft:'10px'}}>Email</Typography>
                                    <CustomTextField name="email" value={details.email} handleChange={handleChange} required />
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Typography variant="body1" color="white" sx={{ fontFamily:'Poppins', fontWeight:'500', fontSize:"5vw", marginX:'10px'}}>Shipping Method</Typography>
                                <Button variant="outlined" sx={{
                                    width:'80vw', 
                                    marginX:"auto", 
                                    marginTop:"5vw",   
                                    padding:"5vw",
                                    border: '2px solid white',
                                    borderRadius: '12px',
                                    '&:hover': {
                                        border: '2px solid white',
                                    },
                                }}>
                                    <Grid container>
                                        <Grid container item xs={7}>
                                            <Grid item xs={12} sx={{
                                                justifyContent:"start",
                                                display:"flex",
                                            }}>
                                                <Radio defaultChecked sx={{
                                                    '& .MuiSvgIcon-root': {
                                                    fill: "white !important",  // Forcing the color to apply
                                                    fontSize: "32px",
                                                    },
                                                    '&.Mui-checked .MuiSvgIcon-root': {
                                                    fill: "white !important",  // Ensuring checked state uses the same color
                                                    },
                                                    padding:"0",
                                                    height:"40px",
                                                    fontSize:"40px",
                                                }} />
                                            </Grid>
                                            <Grid item xs={12}  sx={{
                                                justifyContent:"start",
                                                display:"flex",
                                                height:"40px",
                                                alignItems:"center",
                                            }}>
                                                <Typography variant="body1" color="white" sx={{ fontFamily:'Poppins', fontWeight:'300', fontSize:"4vw", textTransform:"capitalize"}}>Free</Typography>
                                            </Grid>
                                            <Grid item xs={12}  sx={{
                                                justifyContent:"start",
                                                display:"flex",
                                                height:"40px",
                                                alignItems:"center",

                                            }}>
                                                <Typography variant="body1" color="white" sx={{ fontFamily:'Poppins', fontWeight:'300', fontSize:"4vw", textTransform:"capitalize"}}>Regular Shipment</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={5}  sx={{
                                                justifyContent:"end",
                                                display:"flex",
                                                height:"120px",
                                                alignItems:"center",

                                            }}>
                                            <Typography variant="body1" color="white" sx={{ fontFamily:'Poppins', fontWeight:'300', fontSize:"4vw", textTransform:"capitalize"}}>{estimatedArriavalDate}</Typography>
                                        </Grid>
                                    </Grid>
                                </Button>
                            </Grid>
                            <Grid container sx={{
                                width:"80vw",
                                margin:"auto",
                                marginTop:"5vw",
                            }}>
                                <Grid item xs={6}>

                                    <Button variant="outlined" color="primary" margin="auto" component={Link} to="/cart"
                                        sx={{
                                            width: '95%',
                                            padding: '5%',
                                            color: 'white',
                                            border: '2px solid white',
                                            borderRadius: '12px',
                                            textTransform: 'none',
                                            fontFamily:"Poppins",
                                            fontWeight: '400',
                                            '&:hover': {
                                                border: '2px solid white',
                                            },
                                    }}>
                                        Back
                                    </Button> 
                                </Grid>
                                <Grid item xs={6} sx={{
                                    display:"flex",
                                    justifyContent:"flex-end",
                                }}>
                                    <Button variant="outlined" color="primary" margin="auto" type="submit" onSubmit={() => {
                                        
                                    }}
                                    // disabled={shippingOrPayment}
                                        sx={{
                                            width: '95%',
                                            padding: '5%',
                                            color: 'black',
                                            backgroundColor: 'white',
                                            border: '2px solid white',
                                            borderRadius: '12px',
                                            textTransform: 'none',
                                            fontFamily:"Poppins",
                                            fontWeight: '400',
                                            '&:hover': {
                                                border: '2px solid white',
                                                backgroundColor: 'lightgray',
                                            },
                                    }}>
                                        Next
                                    </Button> 
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                ) : (
                    <Grid item xs={12} sx={{ padding:'2%', }}>
                        {clientSecret && (
                            <EmbeddedCheckoutProvider
                                stripe={stripePromise}
                                options={{clientSecret}}
                            >
                                <EmbeddedCheckout />
                            </EmbeddedCheckoutProvider>
                        )}
                    </Grid>
                )}
         
                
                
            </div>
        ) : (
            <div>
                <HeroImgHeader titleA="Checkout" titleB="Checkout"/>
                <form onSubmit={handleSubmit}>
                    <Grid container>
                        <Grid item xs={12} sm={6} sx={{padding:'2%', paddingLeft:{md:'1%', lg:'10%', xl:'20%'}}}>
                            <Typography variant="h4" sx={{ fontFamily:'Poppins', fontWeight:'500', color:'white', width:'100%', textAlign:'left', padding:'2%' }}>
                                Billing details
                            </Typography>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography sx={{ fontFamily:'Poppins', fontWeight:'500', color:'white', marginLeft:'10px'}}>Full Name</Typography>
                                    <CustomTextField name="fullName" value={details.fullName} handleChange={handleChange} required />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography sx={{ fontFamily:'Poppins', fontWeight:'500', color:'white', marginLeft:'10px'}}>Country / Region</Typography>
                                    <CustomCountryField name="country" value={details.country} handleChange={handleChange} required />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography sx={{ fontFamily:'Poppins', fontWeight:'500', color:'white', marginLeft:'10px'}}>Street Address</Typography>
                                    <CustomTextField name="streetAddress" value={details.streetAddress} handleChange={handleChange} required />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography sx={{ fontFamily:'Poppins', fontWeight:'500', color:'white', marginLeft:'10px'}}>Postcode</Typography>
                                    <CustomTextField name="postcode" value={details.postcode} handleChange={handleChange} required />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography sx={{ fontFamily:'Poppins', fontWeight:'500', color:'white', marginLeft:'10px'}}>Phone number</Typography>
                                    <CustomTextField name="phone" value={details.phone} handleChange={handleChange} required />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography sx={{ fontFamily:'Poppins', fontWeight:'500', color:'white', marginLeft:'10px'}}>Email</Typography>
                                    <CustomTextField name="email" value={details.email} handleChange={handleChange} required />
                                </Grid>
                            </Grid>
                        </Grid>
                        {(showStripe === false) ? (
                            <Grid container item xs={12} sm={6}  sx={{ padding:"2%", paddingRight:{md:'1%', lg:'10%', xl:'20%'} }}>
                                <Grid item xs={6} sx={{ borderBottom:'1px solid white' }} >
                                    <Typography color="white" marginTop="20px">
                                        Product
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} sx={{ borderBottom:'1px solid white' }} >
                                    <Typography color="white" textAlign="right" marginTop="20px">
                                        Subtotal
                                    </Typography>
                                </Grid>
                                {cartList.map((product, index) => (
                                    <React.Fragment key={index}>
                                    <Grid item xs={6}>
                                        <Typography color='#9F9F9F'>
                                            {product.name} [{product.size}] X {product.quantity}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography color='#9F9F9F' textAlign="right">
                                            £ {product.price * product.quantity}
                                        </Typography>
                                    </Grid>
                                    </React.Fragment>
                                ))}
                                <Grid item xs={6}>
                                    <Typography color='#9F9F9F'>
                                        Shipping
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography color='#9F9F9F' textAlign="right">
                                        Free
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="h4" color="white" sx={{ paddingTop:'20px', paddingBottom:'10px', borderBottom:'1px solid white'}}>
                                        Total
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="h4" color="white" textAlign="right" sx={{ paddingTop:'20px', paddingBottom:'10px', borderBottom:'1px solid white'}}>
                                        £ {totalPrice}
                                    </Typography>
                                </Grid>
                                <Grid item xs={10}>
                                    <Typography color="white" sx={{ paddingTop:'20px', paddingBottom:'10px'}}>
                                        Please check the box to confirm you have read and agree to our terms and conditions.
                                    </Typography>
                                </Grid>
                                <Grid item xs={2} sx={{ display:"flex", justifyContent:"center", alignItems:"center"}}>
                                    <Checkbox
                                        checked={tandcChecked}
                                        onChange={() => setTandcChecked(!tandcChecked)}
                                        name="checked"
                                        sx={{
                                            color: 'white',
                                            '&$checked': {
                                                color: 'white',
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sx={{ display:"flex", justifyContent:"center"}}>
                                        <Button type="submit" variant="outline" disabled={tandcChecked === false}
                                        sx={{
                                            width: '60%',
                                            padding: '2%',
                                            marginY: '70px',
                                            color: 'white',
                                            border: '2px solid white',
                                            borderRadius: '12px',
                                            textTransform: 'none',
                                            fontSize: '1.5rem',
                                            fontFamily:"Poppins",
                                            fontWeight: '400',
                                            '&:hover': {
                                                border: '2px solid white',
                                            },
                                            '&:disabled': {
                                                border: '2px solid #9F9F9F',
                                                color: '#9F9F9F',
                                            }
                                        }}>
                                            Place order
                                        </Button>
                                </Grid>
                                <Grid item xs={12} height="100%"></Grid>
                            </Grid>
                        ) : (
                            <Grid item xs={12} sm={6} sx={{
                                padding:'2%', paddingRight:{md:'1%', lg:'10%', xl:'20%'}
                            }}>
                                <div>
                                    {clientSecret && (
                                        <EmbeddedCheckoutProvider
                                        stripe={stripePromise}
                                        options={{clientSecret}}
                                        >
                                        <EmbeddedCheckout />
                                        </EmbeddedCheckoutProvider>
                                    )}
                                </div>
                            </Grid>
                        )}
                    </Grid>
                </form>
            </div>
        )};
    </>
    );
}

export default Checkout;