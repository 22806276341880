import React, { useState, useEffect } from 'react';
import HeroImgHeader from '../components/HeroImgHeader';
import { Button, Box, Grid, Typography } from '@mui/material';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import axios from 'axios';
import CustomTextField from '../components/CustomTextField';


const Contact = () => {
    const [formState, setFormState] = useState('initial');
    const [details, setDetails] = useState({
        name: '',
        email: '',
        subject: '',
        message: '',
    });
    const filePath =
  		process.env.NODE_ENV === 'production'
			? 'https://silverriot.co.uk/api/'
			: 'http://127.0.0.1/api/';

    useEffect (() => {
        window.fbq('track', 'PageViewContact');
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setDetails(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const sendEmail = async () => {
            setFormState('loading');
            try {
				await axios.post(filePath + "send_email.php", JSON.stringify({ details:details }), {
					method: 'POST',
                    credentials: 'include',
                });
                setFormState('success');
			} catch (error) {
				console.error(error);
                setFormState('error');
			}}
        sendEmail();
    };
    return (
        <Box sx={{backgroundColor:"#0B0A0A", boxShadow:'none',}}>
            <HeroImgHeader titleA={'Contact'} titleB={'Contact'} />
            <Typography variant="h4" sx={{
                textAlign:"center",
                textTransform:"capitalize", 
                color:"white",
                fontFamily:'Poppins',
                fontWeight:'600',
                marginY:'20px',
            }} >Get in touch with us</Typography>
            <Typography variant="body1" textAlign="center" textTransform="capitalize" color="#9F9F9F" sx={{
                fontSize: { 
                    xs: '2vw', 
                    md: '1.5vw', 
                    lg: '1vw', 
                },
                width:'60%',
                marginBottom:'30px',
                paddingX:'20%'
            }}>
                For More Information About Our Product & Services. Please Feel Free To Drop Us An Email. Our Staff Always Be There To Help You Out. Do Not Hesitate!
            </Typography>
            <Grid container paddingX='10%'>
                <Grid item xs={0} sm={5} sx={{
                    display:{xs:'none', sm:'block'},
                }}>
                    <Grid item xs={12} sx={{
                        display:'flex',
                        flexDirection:'row',
                        justifyContent:'center',
                    }}>
                        <AccessTimeFilledIcon sx={{color:'white', fontSize:'2rem', marginRight:'8px'}} />
                        <Box sx={{ maxWidth:'250px'}}>
                            <Typography variant="h6" textAlign="left" textTransform="capitalize" color="white" fontFamily="Poppins">Working Time</Typography>
                            <Typography variant="body1" textAlign="left" textTransform="capitalize" color="#9F9F9F" fontFamily="Poppins">Monday-Friday: 9:00 - 18:00</Typography>
                            <Typography variant="body1" textAlign="left" textTransform="capitalize" color="#9F9F9F" fontFamily="Poppins">Saturday-Sunday: Closed</Typography>
                        </Box>
                        <AccessTimeFilledIcon sx={{color:'#0B0A0A', fontSize:'2rem', marginRight:'5px'}} />
                    </Grid>
                    <Grid item xs={12} sx={{
                        display:'flex',
                        justifyContent:'center',
                        marginTop:'20px',
                        marginBottom:'10px',    
                    }}>
                        <img src="/images/contactimg.png" alt="contact" style={{width:'100%', maxWidth:'250px', margin:'auto' }} />
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={7} >
                    {formState === 'initial' && (
                        <form onSubmit={handleSubmit}>
                            <Grid item xs={12}>
                                <Typography sx={{ fontFamily:'Poppins', fontWeight:'500', color:'white', marginLeft:'10px'}}>Your name</Typography>
                                <CustomTextField name="name" value={details.name} handleChange={handleChange} placeholder="Abc" required />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography sx={{ fontFamily:'Poppins', fontWeight:'500', color:'white', marginLeft:'10px'}}>Email address</Typography>
                                <CustomTextField name="email" value={details.email} handleChange={handleChange} placeholder="Abc@def.com" required />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography sx={{ fontFamily:'Poppins', fontWeight:'500', color:'white', marginLeft:'10px'}}>Subject</Typography>
                                <CustomTextField name="subject" value={details.subject} handleChange={handleChange} placeholder="This is optional" />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography sx={{ fontFamily:'Poppins', fontWeight:'500', color:'white', marginLeft:'10px'}}>Message</Typography>
                                <CustomTextField name="message" value={details.message} handleChange={handleChange} placeholder="Hi! I'd like to ask about" required />
                            </Grid>
                            <Box sx={{
                                display:'flex',
                                justifyContent:{xs:'center',sm:'flex-start'},
                                marginTop:{xs:'20px',sm:'0'},
                            
                            }}>
                                <Button variant='outlined' type="submit" sx={{ marginLeft:'10px', color: 'white',
                                        backgroundColor: '#721E1D',
                                        borderColor: 'white',
                                        borderRadius: '10px',
                                        padding: '10px',
                                        paddingX: '20px',
                                        textTransform: 'none',
                                        fontFamily: 'Poppins',
                                        fontWeight: '400',
                                        fontSize: {xs:'20px',sm:'2vw'},
                                        '&:hover': {
                                            borderColor: 'white',
                                            backgroundColor: '#5f1c1a',
                                        },
                                        marginBottom:'50px',
                                    }}>
                                    Submit
                                </Button>
                            </Box>
                        </form>
                    )}
                    {formState === 'loading' && (
                        <Typography color={'white'}>Loading...</Typography>
                    )}
                    {formState === 'success' && (
                        <Typography color={'white'}>Thank you for your message!</Typography>
                    )}
                    {formState === 'error' && (
                        <Typography color={'white'}>Unfortunately your message couldnt be sent at this time. Please try again later.</Typography>
                    )}
                </Grid>
                <Grid item xs={12} sx={{
                    display:{xs:'block', sm:'none'},
                }}>
                    <Grid item xs={12} sx={{
                        display:'flex',
                        flexDirection:'row',
                        justifyContent:'center',
                    }}>
                        <AccessTimeFilledIcon sx={{color:'white', fontSize:'2rem', marginRight:'8px'}} />
                        <Box sx={{ maxWidth:'250px'}}>
                            <Typography variant="h6" textAlign="left" textTransform="capitalize" color="white" fontFamily="Poppins">Working Time</Typography>
                            <Typography variant="body1" textAlign="left" textTransform="capitalize" color="#9F9F9F" fontFamily="Poppins">Monday-Friday: 9:00 - 18:00</Typography>
                            <Typography variant="body1" textAlign="left" textTransform="capitalize" color="#9F9F9F" fontFamily="Poppins">Saturday-Sunday: Closed</Typography>
                        </Box>
                        <AccessTimeFilledIcon sx={{color:'#0B0A0A', fontSize:'2rem', marginRight:'5px'}} />
                    </Grid>
                    <Grid item xs={12} sx={{
                        display:'flex',
                        justifyContent:'center',
                        marginTop:'20px',
                        marginBottom:'50px',    
                    }}>
                        <img src="/images/contactimg.png" alt="contact" style={{width:'100%', maxWidth:'250px', margin:'auto' }} />
                    </Grid>
                </Grid>
            </Grid>
        </Box>
        
    );
};

export default Contact;