import React, { useState, useEffect } from 'react';
import { Typography, Button, Grid } from '@mui/material';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import HeroImgHeader from '../components/HeroImgHeader';


const Order = ({ handleClearCart }) => {
    const { id } = useParams();
    const [order, setOrder] = useState({});
    const [emailState, setEmailState] = useState(0); // 0 : both unsent, 1 : email to us sent, 2 : email to us and user sent, -1 : error
    const filePath =
  		process.env.NODE_ENV === 'production'
			? 'https://silverriot.co.uk/api/'
			: 'http://127.0.0.1/api/';

    useEffect(() => {
		const getOrder = async () => {
			try {
				const response = await axios.post(filePath + "get_order.php", {id:id}, {
					method: 'POST',
                    credentials: 'include',
                });
                if (response.data.success) { 
                    handleClearCart();
                }
				setOrder(response.data.order);
                window.fbq('track', 'Purchase');
                setEmailState(1);
                console.log(response.data, "response");
			} catch (error) {
				console.error(error);
			}
		};
        getOrder();
    }, [id, filePath, handleClearCart]);

    useEffect(() => {
        if (emailState === 1) {
            const sendEmailToUs = async () => {
                try {
                    await axios.post(filePath + "send_email.php", JSON.stringify({
                        order: {
                            name: order.first_name,
                        }
                    }), {
                        method: 'POST',
                        credentials: 'include',
                    });
                } catch (error) {
                    console.error(error);
                }}
            sendEmailToUs();
        }
    }, [filePath, emailState]);

    return (
        <div >
            <HeroImgHeader titleA={'Confirmation'} titleB={'Confirmation'} />
            <Typography variant="h4" sx={{ fontFamily:'Poppins', color:'white', textAlign:'center', fontWeight:'600', width:'100%', marginTop:'80px' }}>Order #{order.order_id} is on its way!</Typography>
            <Typography variant="body1" sx={{ fontFamily:'Poppins', color:'#9F9F9F', textAlign:'center', fontWeight:'400', width:'100%' }}>Thank you for purchasing with Silver Riot! Your order is on its way!</Typography>
            <Typography variant="body1" sx={{ fontFamily:'Poppins', color:'#9F9F9F', textAlign:'center', fontWeight:'400', width:'100%' }}>Get involved in our community by sharing your purchase on social media below!</Typography>
            <Grid container>
                <Grid item xs={12} sm={6} sx={{ display:'center', justifyContent:'center'}}>
                    <Button variant='outlined'
                    sx={{
                        color: 'white',
                        borderColor: 'white',
                        '&:hover': {
                            borderColor: 'white',
                        },
                        paddingX: '30px',
                        paddingY: '10px',
                        textTransform: 'none',
                        borderRadius: '15px',
                        width:'shrink',
                        margin:'auto',
                        marginTop:'50px',
                        marginBottom:'50px',
                        fontFamily:'Poppins',
                        fontWeight:'400',
                    }}>Instagram</Button>
                </Grid>
                <Grid item xs={12} sm={6} sx={{ display:'center', justifyContent:'center'}}>
                    <Button variant='outlined'
                    sx={{
                        color: 'white',
                        borderColor: 'white',
                        '&:hover': {
                            borderColor: 'white',
                        },
                        paddingX: '30px',
                        paddingY: '10px',
                        textTransform: 'none',
                        borderRadius: '15px',
                        width:'shrink',
                        margin:'auto',
                        marginTop:'50px',
                        marginBottom:'50px',
                        fontFamily:'Poppins',
                        fontWeight:'400',
                    }}>TikTok</Button>
                </Grid>
                <Typography variant="h5" sx={{ fontFamily:'Poppins', color:'white', textAlign:'center', fontWeight:'600', width:'100%', marginTop:'20px' }}>#WHATSYOURRIOT</Typography>
                <Button variant='outlined' component={Link} to='/contact'
                    sx={{
                        color: 'white',
                        borderColor: 'white',
                        '&:hover': {
                            borderColor: 'white',
                        },
                        paddingX: '30px',
                        paddingY: '10px',
                        textTransform: 'none',
                        borderRadius: '15px',
                        width:'shrink',
                        margin:'auto',
                        marginTop:'50px',
                        marginBottom:'50px',
                        fontFamily:'Poppins',
                        fontWeight:'400',
                    }}>Contact Us</Button>
            </Grid>
        </div>
    );
};

export default Order;
